<template>
  <div class="product-stock-modal">
    <b-modal
      :id="refModal"
      :ref="refModal"
      title="Cập nhật tồn kho"
      size="lg"
      hide-footer
    >
      <p class="my-4">*** Bạn có thể nhập toán tử và số lượng tồn cần điều chỉnh sao cho khớp với thực tế ***</p>
      <p class="product-name"><b>Sản phẩm:</b> {{product.productName}}</p>
      <div class="store">
        <b-row>
          <b-col>
            <b-form-group>
              <label class="font-weight-bolder">Cửa hàng</label>
              <Autosuggest
                :model="searchStock"
                :suggestions="filteredOptions"
                :placeholder="'cửa hàng'"
                :limit="10"
                @select="onSelectStore($event)"
                @change="onInputChangeStore($event)"
                suggestionName="name"
              />
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
      </div>

      <div class="product-stock">
        <table class="table table-bordered table-vertical-center table-hover">
          <thead>
            <tr>
              <th class="th-stock-type">Loại tồn kho</th>
              <th class="th-operator">Toán tử</th>
              <th class="th-quantity">Số lượng</th>
            </tr>
          </thead>
          <tr>
            <th>Có thể bán</th>
            <td>
              <b-form-select
                size="sm"
                v-model="mainModel.inStockQuantity.operator"
                :options="operatorOptions"
              >
              </b-form-select>
            </td>
            <td>
              <BInputNumber
                size="sm"
                v-model="mainModel.inStockQuantity.quantity"
                placeholder="Nhập số lượng"
                class="text-right"
              />
            </td>
          </tr>
          <tr>
            <th>Đang chuyển kho</th>
            <td>
              <b-form-select
                size="sm"
                v-model="mainModel.transferQuantity.operator"
                :options="operatorOptions"
              >
              </b-form-select>
            </td>
            <td>
              <BInputNumber
                size="sm"
                v-model="mainModel.transferQuantity.quantity"
                placeholder="Nhập số lượng"
                class="text-right"
              />
            </td>
          </tr>
          <tr>
            <th>Đang giữ hàng</th>
            <td>
              <b-form-select
                size="sm"
                v-model="mainModel.holdingQuantity.operator"
                :options="operatorOptions"
              >
              </b-form-select>
            </td>
            <td>
              <BInputNumber
                size="sm"
                v-model="mainModel.holdingQuantity.quantity"
                placeholder="Nhập số lượng"
                class="text-right"
              />
            </td>
          </tr>
          <tr>
            <th>Đang vận chuyển</th>
            <td>
              <b-form-select
                size="sm"
                v-model="mainModel.deliveryQuantity.operator"
                :options="operatorOptions"
              >
              </b-form-select>
            </td>
            <td>
              <BInputNumber
                size="sm"
                v-model="mainModel.deliveryQuantity.quantity"
                placeholder="Nhập số lượng"
                class="text-right"
              />
            </td>
          </tr>
          <tr>
            <th>Đang bảo hành</th>
            <td>
              <b-form-select
                size="sm"
                v-model="mainModel.warrantyQuantity.operator"
                :options="operatorOptions"
              >
              </b-form-select>
            </td>
            <td>
              <BInputNumber
                size="sm"
                v-model="mainModel.warrantyQuantity.quantity"
                placeholder="Nhập số lượng"
                class="text-right"
              />
            </td>
          </tr>
        </table>
      </div>

      <div class="submit">
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSave()"
        >Lưu</b-button>
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal()"
        >Hủy</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { mapActions } from 'vuex';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Autosuggest,
    BInputNumber,
  },
  computed: {},
  data() {
    return {
      idempotenceKey: null,
      searchStock: '',
      filteredOptions: [],
      optionsStore: [],
      refModal: 'modal-product-stock',
      storeId: null,
      storeOptions: [],
      operatorOptions: [
        { value: null, text: 'Chọn toán tử' },
        { value: 1, text: '+' },
        { value: 2, text: '-' },
      ],
      mainModel: {
        storeId: null,
        productId: null,
        inStockQuantity: {
          quantity: 0,
          operator: null,
        },
        transferQuantity: {
          quantity: 0,
          operator: null,
        },
        holdingQuantity: {
          quantity: 0,
          operator: null,
        },
        deliveryQuantity: {
          quantity: 0,
          operator: null,
        },
        warrantyQuantity: {
          quantity: 0,
          operator: null,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.getAllStoreAction({
      success: this.handleSuccess,
      fail: this.handleFail,
    });
  },
  methods: {
    ...mapActions(['getAllStoreAction']),
    handleSuccess(data) {
      data.map((element) => {
        this.optionsStore.push(element);
      });
      this.filteredOptions = [...this.optionsStore];
    },
    resetMainModelData() {
      this.mainModel.storeId = null;
      this.searchStock = '';
      this.mainModel.productId = null;
      this.mainModel.inStockQuantity.quantity = 0;
      this.mainModel.inStockQuantity.operator = null;
      this.mainModel.transferQuantity.quantity = 0;
      this.mainModel.transferQuantity.operator = null;
      this.mainModel.holdingQuantity.quantity = 0;
      this.mainModel.holdingQuantity.operator = null;
      this.mainModel.deliveryQuantity.quantity = 0;
      this.mainModel.deliveryQuantity.operator = null;
      this.mainModel.warrantyQuantity.quantity = 0;
      this.mainModel.warrantyQuantity.operator = null;
    },
    onSelectStore(option) {
      this.searchStock = option.item.name;
      this.mainModel.storeId = option.item.id;
    },
    onInputChangeStore(text = '') {
      const filteredData = this.optionsStore.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });

      this.filteredOptions = [...filteredData];
    },
    handleFail() {},
    onSave() {
      if (!this.mainModel.storeId) {
        return makeToastFaile('Vui lòng nhập cửa hàng');
      }

      if (!this.idempotenceKey) {
        this.idempotenceKey = uuidv4();
      }

      this.mainModel.productId = this.product.id;
      ApiService.post(
        'stock-slips/adjust-stock-slip',
        [this.mainModel],
        this.idempotenceKey,
      )
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
          } else {
            makeToastFaile(message);
          }

          this.idempotenceKey = uuidv4();
          this.hideModal();
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    hideModal() {
      this.resetMainModelData();
      this.$bvModal.hide('modal-product-stock');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>